import React, { createRef, forwardRef, useEffect, useRef } from 'react';

import { LinkNav, HomeIcon, AboutIcon, TeamIcon, ServicesIcon, BlogIcon, EbookIcon, InstagramIcon, ContactIcon } from './styles';
import { Nav } from '../styles';
import { P } from '../../../styles/Fonts';
import { REPLCommand } from 'repl';

interface Props {
	path: string;
	label: string;
	direction : "up" | "down",
	
}

const Li: React.FC<Props> = ({
	path, label,direction
}) => {


	

	function returnButtonType(): React.ReactNode {
		switch(label) {
			case 'Home':
				return (
						<>	
						<HomeIcon />
						Home
						</>
		
				);
			case 'Sobre':
				return (
						<>
						<AboutIcon />
						Sobre
						</>
				)
			case 'Time':
				return (
						<>
						<TeamIcon/>
						Time
						</>
				)
			case 'Serviços':
				return (
					<>
						<ServicesIcon />
						Servicos
					</>
				)
			case 'Blog':
				return (
						<>
						<BlogIcon />
						Blog
						</>
				)
			case 'Ebook':
				return (
						<>
						<EbookIcon />
						Ebook
						</>
		
				)
			case 'Instagram':
				return (
						<>
						<InstagramIcon/>
						Instagram
						</>
		
				)
			case 'Contato':
				return (
						<>
						<ContactIcon/>
						Contato
						</>
	
				)
		}
	}
	return <li style={{listStyle :"none"}}>	<LinkNav
	direction={direction}
	entryOffset={100}
	duration={2}
	top={'entry'}


	to={path}>{returnButtonType()}</LinkNav  ></li>;
}

export default Li;