import React, {  createContext, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {ThemeProvider} from 'styled-components';
import { GlobalStyle } from '../../styles/GlobalStyle';
import {Theme} from '../../styles/theme';
import Navbar from '../NavBar';
import media from '../../styles/utils/media';

import MobileNavBar from '../../components/MobileNavBar';
import LogoIcon from '../../assets/icons/coloredLogo.png';
import StatueImg from '../../../static/images/DeusaDoDireitoX2.png';
import { Link } from 'gatsby';
import useMediaQuery from '../../Hooks/useMediaQuery';
import MobileCircleNavBar from '../MobileCircleNavBar';
import usePages from '../../Hooks/getPages';
import { useSpring , animated, useTransition } from 'react-spring';




export const Logo = styled.div`
display:none;
    ${media.sm`
        display:block;
        position: absolute;
        top: calc(100% - 70px);
        left: calc(100% - 60px);
        width: 37.42px;
        height: 40px;
        background-image: url(${LogoIcon});
        z-index:0;

    `}
 
  
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height:100vh;
    padding-bottom:1rem;
 
     .tl-edges{
        flex: 1;
        display:flex;
        
    }
    .tl-wrapper{
        display:flex;
        flex-direction:column;
        flex:1;
        overflow-x:hidden;

        position : static;
    }  

    ${media.sm`
    padding-bottom:0rem;
    /* .tl-wrapper{
        transform: translate(0px, 0px) !important;
        position : relative;
    } */
        max-width: 2000px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 17.3vw auto;
        grid-template-rows: 100vh;
    ` }

`;

export const  MobileButtonTalkToUs = animated(styled(Link)`
    display:flex;
    align-self: center;
    justify-self : center;

    align-items: center;
    justify-content: center;
    
    width: 84vw;
    height : 8vh;
    min-height: 4.5rem;
    margin-top: 1rem;


    text-decoration : none;

    font-size : ${(props)=>props.theme.fontSize.S5};
    color : ${(props)=>props.theme.colors.background};
    font-family : ${(props)=>props.theme.fontFamily.roboto};


    color : ${(props)=>props.theme.colors.background};
    background-color: ${(props)=>props.theme.colors.primary};


    border-radius : 1000px;
    border: none;


    z-index:2;
    ${media.sm`
        display: none;
    `}


`)

 const BackgroundMobileStyled = styled.div`

        width: 100vw;
        height: calc(100vh + 20px);
        position :  absolute;
        
        top: -20px;
                
        background-image: url(${StatueImg});
        background-repeat: no-repeat;
        background-size: auto 130vh;
        background-position: 29vw -40px;    
        z-index:-1;

`
export const BackgroundMobile = animated(BackgroundMobileStyled);
interface LayoutProps{
    children : ReactNode;
    location : {
        pathname : string;
    };
}

const pageMenu =[
    'Home',
    'Serviços',
    'Sobre',
    'Time',
    'Contato',
]

interface PageBaseProps{
    relativePath : string;
    title : string;
    active : boolean;
}
interface PagePathProps extends PageBaseProps{
    previousPage : PageBaseProps | undefined;
    nextPage: PageBaseProps | undefined;
}
interface PagesLocation {
    pages : Array<PagePathProps>
}

export const PageContext = createContext<PagesLocation>({pages : []});

interface PageProviderProps{
    children ?: ReactNode;
    location : {pathname: string};
}
export const PageProvider = ({children,location} :PageProviderProps)=>{
    const pagesStatic = usePages();
    const [pages ,setPages] = useState<PagesLocation>()
    useEffect(()=>{   
        
        const pagesUpdatedIncludesUndefined =  pagesStatic.map(
            (a,index)=>pagesStatic
                .find((page)=> page.title === pageMenu[index])
            );
        const PagesUpdatedOrUndefined = pagesUpdatedIncludesUndefined.filter((p)=>p !== undefined) as typeof pagesStatic;

        

       setPages({
           pages : PagesUpdatedOrUndefined.map((page,i,pages)=>{

             const regex = `^[\/]?${page.path.replace(/\//g,'')}[\/]?$`;
             return{
                 active : !!location.pathname.match(new RegExp(regex)),
                 relativePath : page.path,
                 title : page.title,
             };
           }).map((page,i,pages)=>{
               return{
                   ...page,
                   nextPage : pages[i+1],
                   previousPage : pages[i-1]
               }
           })
       })

    },[location])

    if(!pages){
        return ( <p>sadasd</p> )
    }
    return(
        <PageContext.Provider value={pages}>
        {children}
        </PageContext.Provider>
    )
}


export default function Layout({children,location} : LayoutProps):JSX.Element{  
    const isXsm = useMediaQuery(`(max-width : ${Theme.breakpoints.sm}px)`);
    const isContactPage = !!location.pathname.match(/contato/);
    const isHomePage = !!location.pathname.match(/^\/$/);
    const { x } = useSpring({ from: { x: 0 }, x: isHomePage? 1 : 0, config: { duration: 500 } });

   
   
    return(
        <ThemeProvider theme={Theme}>
            <PageProvider location={location}>
                <GlobalStyle/>
                <PageContainer>
                    {isXsm?
                    (
                    <>
                    <MobileNavBar/>
                    <BackgroundMobile style={{
                        opacity: x.interpolate({ range: [0,0.25,0.50,0.75, 1], output: [0,0.25,0.50,0.75, 1] }),
                    }}/>
                    </>
                    ):
                    (<Navbar location={location} />)}
                    {children}
                <div style={{zIndex:1, alignSelf:'center'}}>
                {!isContactPage?(
                    <MobileButtonTalkToUs>Fale Conosco</MobileButtonTalkToUs>): ('')}
                {isXsm&&(<MobileCircleNavBar />)}
                </div>
                </PageContainer>
            </PageProvider>
        </ThemeProvider>
    )
}


