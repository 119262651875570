import styled, {createGlobalStyle} from 'styled-components';
import { PageContainer } from '../components/Layout';
import media from '../styles/utils/media'
export const GlobalStyle = createGlobalStyle`
  :root {
      font-size: 62.5%;
     
     

  }
  *{

    box-sizing: border-box;
  }
  html,h1,h3,h4,h5,h6{
    font-family : 'Roboto Slab',sans-serif; 

  }

  
  body {
    overflow-x: hidden; 
    background-color: ${props => props.theme.colors.background};
    height:100vh;
    width:100vw;
    margin: 0;
    padding: 0;
  }
  ::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-track {
  background: ${(props)=>props.theme.colors.background};        /* color of the tracking area */
  }
  ::-webkit-scrollbar-thumb {
  background-color: ${(props)=>props.theme.colors.primary};    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 10px solid ${(props)=>props.theme.colors.primary};  
  }
  p{
    font-family : 'Raleway',sans-serif;
  }


`;
