import React from 'react';
import styled from 'styled-components';
import media from '../../styles/utils/media';
import { Link } from 'gatsby';

import Logo from '../../assets/icons/coloredMobileLogo.png';
import MobileMenu from '../../assets/icons/mobileMenu.png';
import Home from '../../assets/icons/homeIcon.svg';
import About from '../../assets/icons/aboutIcon.svg';
import Team from '../../assets/icons/teamIcon.svg';
import Services from '../../assets/icons/servicesIcon.svg';
import Blog from '../../assets/icons/blogIcon.svg';
import Ebook from '../../assets/icons/ebookIcon.svg';
import Instagram from '../../assets/icons/instagramIcon.svg';
import Contact from '../../assets/icons/contactIcon.svg';
import Shark from '../../assets/images/logoSharkCode.png';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin-top: 25px;
    /* background-color: black; */

    ${media.sm`
        display: none;
    `}

    ${media.xlg`
        
    `}
`;

export const LogoTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoIcon = styled.div`
    width: 50px;
    height: 48px;
    margin-left: 30px;
    background-image: url(${Logo});
    background-position: center;
`;

export const Title = styled.p`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSize.S6};
`;

export const ButtonMenu = styled.button`
   width: 35px;
   height: 32px;
   margin-right: 40px;
   background-image: url(${MobileMenu});
   background-repeat: no-repeat;
   outline: none;
   border: none;
   background-color: transparent;
`;

export const BackgroundNav = styled.div`
    display: flex;
    flex-direction: column;
    outline: none;

    height: auto;
    width: auto;
`;

export const ButtonsNav = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-left: 0; */
    /* margin-top: 30px; */
    background-color: transparent;
`;

export const Button = styled(Link)`
    display: flex;
    width: calc(100vw - 80px);
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.textP};
    background-color: transparent;
`;

export const HomeIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Home});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const SobreIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${About});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const TimeIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Team});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const ServicosIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Services});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const BlogIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Blog});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const EbookIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Ebook});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const InstagramIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Instagram});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const ContatoIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-image: url(${Contact});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const P = styled.p`
    font-size: ${(props) => props.theme.fontSize.SP};
    color: ${(props) => props.theme.colors.textP};
    margin-left: 10px;
`;

export const Credits = styled.p`
    margin-top: calc(100vh - 565px);
    font-size: ${(props) => props.theme.fontSize.SP};
    font-family: ${(props) => props.theme.fontFamily.roboto};
    color: ${(props) => props.theme.colors.textP};
`;

export const SharkLogo = styled.div`
    width: 120.99px;
    height: 16.5px;
    margin-top: calc(100vh - 563px);
    margin-left: 0.8rem;
    background-image: url(${Shark});
    
    background-repeat: no-repeat;
    background-size: cover;
`;