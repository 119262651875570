import React, { createRef, useContext, useEffect, useRef, useState } from 'react';

import { Nav, Ul } from './styles';
import Li from './Li';
import { object, string } from 'prop-types';
import { graphql, PageProps, navigate } from 'gatsby';
import getPages from '../../Hooks/getPages';
import usePages from '../../Hooks/getPages';
import { useSwipeLinkTransition }  from '../../components/TransitionLink'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import useMediaQuery from '../../Hooks/useMediaQuery';
import { useTheme } from 'styled-components';
import useWindowPosition from '../../Hooks/useWindownPosition';
import { P } from '../../styles/Fonts';
import { PageContext } from '../Layout';


const pageMenu =[
    'Serviços',
    'Contato',
    'Home',
    'Sobre',
    'Time',
]
interface NavProps {
    location : {
        pathname : string;
    };
}
const Navbar = ( { location  } : NavProps) : JSX.Element => {
    useContext(PageContext);
    const navigateUp =  useSwipeLinkTransition({duration : 2,entryOffset : 100 ,direction :"up"} as any);
    const navigateDown =  useSwipeLinkTransition({ duration : 2,entryOffset : 100 ,direction :"down"} as any);
    const pages = usePages();

    const pagesFiltered = pages.map((a,index)=>pages.find((page)=> page.title === pageMenu[index])).filter((page)=>page) as typeof pages;
    const theme = useTheme();

    const isXsm =  useMediaQuery(`(max-width : ${theme.breakpoints.sm}px)`);
    type Position = {  "1": number,"2": number, "3" : number,"4" :number,"5" :number} | undefined
    const[position,setPosition] = useState<Position>();
    const [keyCenterIndex, setKeyCenterIndex] = useState<number>(pagesFiltered.findIndex((page)=> page.path === location.pathname));

    const [scrollPosition,hasScroll] = useWindowPosition();
    const [scrollCount, setScrollCount] = useState(0);


    
  
    useEffect(()=>{
        const centerIndex = pagesFiltered.findIndex((page)=> page.path === location.pathname);
        setKeyCenterIndex(centerIndex);
       
       
 
       
    },[location.pathname])
    useEffect(()=>{


    setPosition({
            "1" : keyCenterIndex -2 >= 0 ? keyCenterIndex -2 : pagesFiltered.length + (keyCenterIndex - 2),
            "2" : keyCenterIndex -1 >= 0 ? keyCenterIndex -1 : pagesFiltered.length + (keyCenterIndex - 1),
            "3" :  keyCenterIndex,
            "4" : keyCenterIndex +1 <= pagesFiltered.length - 1 ? keyCenterIndex + 1 : (keyCenterIndex + 1) - pagesFiltered.length,
            "5" : keyCenterIndex + 2 <= pagesFiltered.length - 1 ? keyCenterIndex + 2 : (keyCenterIndex + 2) - pagesFiltered.length,
        });
    },[keyCenterIndex])

    useEffect(()=>{
        window.onwheel = (event : WheelEvent)=>{
            if(isXsm){
                return;
            }
            // if(scrollCount < 5){
            //     setScrollCount(scrollCount + 1);
            //     return;
            // }
            const {deltaY} = event; 
    
            const isInBottom = scrollPosition == 1 || (!hasScroll);
            const scrollBottom = deltaY > 0 ;
            const scrollTop = deltaY < 0 ;
            const isInTop = scrollPosition == 0;
            // console.log(`has Scroll ${hasScroll}`)
            // console.log(`Scroll Button ${scrollBottom}`,`Is Bottom : ${isInBottom}` );
            // console.log(`Scroll Up ${scrollTop}`,`Is Up : ${isInTop}`);
            if(!position)return null;
            if(scrollBottom && isInBottom ){
                if(scrollCount < 1){
                    setScrollCount(scrollCount + 1);
                    return;
                }
    
                setScrollCount(0);
                navigateUp({
                    to: pagesFiltered[position[4]].path
                })
          
           
            }else if(scrollTop && isInTop){
                if(scrollCount < 1){
                    setScrollCount(scrollCount + 1);
                    return;
                }
                setScrollCount(0);
                navigateDown({
                    to: pagesFiltered[position[2]].path
                })
            
            }
        }
        return ()=>{window.onwheel = (()=>{});}
    })
   

    if(keyCenterIndex == -1 ){
        return (<p> </p>);
    }
    if(!position){
        return( <p>loading</p> )
    }

    if(!pagesFiltered){
        return( <p>loading</p> )
    }



 







    return(
    <Nav>
        <Ul>
            <Li  direction={'down'} path={pagesFiltered[position[1]].path} label={pagesFiltered[position[1]].title}/>
            <Li  direction={'down'} path={pagesFiltered[position[2]].path} label={pagesFiltered[position[2]].title}/>
            <Li   direction={'up'} path={pagesFiltered[position[3] ].path} label={pagesFiltered[position[3] ].title}/>
            <Li  direction={'up'} path={pagesFiltered[position[4]].path} label={pagesFiltered[position[4]].title}/>
            <Li   direction={'up'} path={pagesFiltered[position[5]].path} label={pagesFiltered[position[5]].title}/>
        </Ul>
    </Nav>
)

}

export default Navbar;

