import { graphql, useStaticQuery } from "gatsby";


interface PagesProps {
    allMarkdownRemark : {
        edges : [{
          node : {
            fields : {
              slug : string;
            }
            frontmatter :{
                page : string;  
          }
        }
      }]
}
}

const usePages = () => {
    const {allMarkdownRemark : {edges}}  = useStaticQuery<PagesProps>(
      graphql`
        query SITE_PAGES_QUERY {
  allMarkdownRemark(limit: 1000) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          page
        }
      }
    }
  }
}
      `
    );
    return edges.map(edge => ({path : edge.node.fields.slug,title : edge.node.frontmatter.page}))
  }
  
  export default usePages;
 