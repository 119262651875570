import React,{ useState ,useContext} from 'react';

import burgerStyle from './burger.css';

import { slide as Menu } from 'react-burger-menu'

import { 
    Container,
    LogoTitle,
    LogoIcon,
    Title,
    Button,
    HomeIcon,
    SobreIcon,
    TimeIcon,
    ServicosIcon,
    BlogIcon,
    EbookIcon,
    InstagramIcon,
    ContatoIcon,
    P,
    ButtonsNav,
    Credits,
    BackgroundNav,
    SharkLogo
} from './styles';
import { PageContext } from '../Layout';


const MobileNavbar = ( ) => {
    const [menuStatus, setMenuStatus] = useState(false);

    function closeMenu() {
        return setMenuStatus(false);
    }


     useContext(PageContext);
    return(
        <Container>
            <LogoTitle>
                <LogoIcon />
                <Title>ConJus</Title>
            </LogoTitle>
            <Menu 
                width="100vw"

                styles={burgerStyle}

                pageWrapId={ "page-wrap" } 
                outerContainerId={ "outer-container" }

                isOpen={menuStatus}
                onStateChange={(state)=>setMenuStatus(state.isOpen)}

                right
            >
                <BackgroundNav>
                    <ButtonsNav>
                        <Button to={"/"} style={{ textDecoration: "none"}} onClick={closeMenu}>
                            <HomeIcon />
                            <P>Home</P>
                        </Button>
                        <Button to={"/sobre/"} style={{ textDecoration: "none"}} onClick={closeMenu}>
                            <SobreIcon />
                            <P>Sobre</P>
                        </Button>
                        <Button to={"/time/"} style={{ textDecoration: "none"}} onClick={closeMenu}>
                            <TimeIcon />
                            <P>Equipe</P>
                        </Button>
                        <Button to={"/servico/"} style={{ textDecoration: "none"}} onClick={closeMenu}>
                            <ServicosIcon />
                            <P>Serviços</P>
                        </Button>
                        <Button to={"#"} >
                            <BlogIcon />
                            <P>Blog</P>
                        </Button>
                        <Button to={"#"}>
                            <EbookIcon />
                            <P>eBook</P>
                        </Button>
                        <Button to={"#"} >
                            <InstagramIcon />
                            <P>Instagram</P>
                        </Button>
                        <Button to={"/contato/"} style={{ textDecoration: "none"}} onClick={closeMenu}>
                            <ContatoIcon />
                            <P>Contato</P>
                        </Button>
                </ButtonsNav>
                    <div className="creditLogo" style={{ display: "flex"}}>
                        <Credits>Developed by</Credits>
                        <SharkLogo />
                    </div>
                </BackgroundNav>
            </Menu>
        </Container>
    )
}

export default MobileNavbar;