import styled from 'styled-components';

import Home from '../../../assets/icons/homeIcon.svg';
import About from '../../../assets/icons/aboutIcon.svg';
import Team from '../../../assets/icons/teamIcon.svg';
import Services from '../../../assets/icons/servicesIcon.svg';
import Blog from '../../../assets/icons/blogIcon.svg';
import Ebook from '../../../assets/icons/ebookIcon.svg';
import Instagram from '../../../assets/icons/instagramIcon.svg';
import Contact from '../../../assets/icons/contactIcon.svg';
import  TransitionLink from '../../TransitionLink';


export const LinkNav = styled(TransitionLink)`
	display: flex;
	align-items: center;
	justify-content: center;


	border-radius: 8px;

	margin-top: 7.14vh;
	margin-bottom: 7.14vh;

	cursor: pointer;
	text-decoration:none;
	font-size : ${(props)=>props.theme.fontSize.SP};
	color: ${(props)=>props.theme.colors.textP};

	


`;

export const HomeIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Home});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const AboutIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${About});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const TeamIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Team});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const ServicesIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Services});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const BlogIcon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 10px;
	background-image: url(${Blog});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const EbookIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Ebook});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const InstagramIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Instagram});
	background-repeat: no-repeat;
	background-size: 100%;
`;

export const ContactIcon = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 10px;
	background-image: url(${Contact});
	background-repeat: no-repeat;
	background-size: 100%;
`;	