import { func } from 'prop-types';
import React, { SetStateAction, useLayoutEffect, useState } from 'react';

export default function useWindowPosition() {
  const [scrollPosition, setPosition] = useState(0);
  const [hasScroll,setScroll] = useState(false);
  const [state,toggle] = useState(false);
  function updateState(){
    toggle(!state);
  }

  
  //Falta Atualizar quando diminui a tela , por algum motivo hasScoll não está atualizando
  useLayoutEffect(() => {
    function updatePosition() {
        const element = document.getElementsByClassName('tl-wrapper')[0];
        const windowHeight = window.screenY;

        setScroll(element.scrollHeight != element.clientHeight);
        const aux = ( element.scrollHeight - element.clientHeight)?( element.scrollHeight - element.clientHeight): 1;
        setPosition(element.scrollTop /aux );
        element.addEventListener('scroll',updateState);
        return element;
    }
    const element = updatePosition();
    
    return () =>{console.log('removido event listener');element.removeEventListener('scroll', updateState);}
  }, [window.history.state,state]);
  return [scrollPosition,hasScroll];
}