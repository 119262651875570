import React, { ReactNode } from 'react';
import TransitionLink from '../TransitionLink';
import circleIconActive from '../../assets/icons/circleIconActive.svg';
import circleIconOff from '../../assets/icons/circleIconDesactive.svg';
import styled from 'styled-components';


interface CircleIconProps{
    active ?: boolean;
    to : string;
    children ?: ReactNode;
    activeIndex : number;
    index : number;
}

const Img = styled.img`
    height:100%;
`

const Link = styled(TransitionLink)`
    width: 2vh;
    height: 2vh;
`

export default function CircleIcon({activeIndex,index,active = false , to,children} : CircleIconProps){
    const direction = active? 'left' : activeIndex < index?'left' : 'right';
    return(
        <Link duration={2} entryOffset={100} direction={direction} to={to}> 
        <Img src={active?circleIconActive:circleIconOff} alt="nav circle"/>
        {children}
        </Link>
    )

}