import React, { useContext, useEffect } from 'react';
import { animated } from 'react-spring';
import styled, { useTheme } from 'styled-components';
import useMediaQuery from '../../Hooks/useMediaQuery';
import media from '../../styles/utils/media';
import CircleIcon from '../CircleIcon';
import { PageContext } from '../Layout';
import { useSwipeLinkTransition } from '../TransitionLink';

interface MobileCircleNavBarProps{

}

const ContainerUl = styled.ul`
    width:100%;
    
    display : flex;
    flex-direction: row;
    justify-content:center;
    z-index:10;

    ${media.sm`
      display :none;
    `}
`;
const LiStyled = styled.li`
    display : flex;
    flex-direction: row;

    margin-right:2rem;

    
    list-style: none;


`
const  MobileCircleNavBarComponent = function({} : MobileCircleNavBarProps) {
    const {pages} = useContext(PageContext);
    const theme = useTheme();
    const activeIndex = pages.findIndex((page)=>page.active);
    const navigateLeft =  useSwipeLinkTransition({duration : 2,entryOffset : 100 ,direction :"left"} as any);
    const navigateRight =  useSwipeLinkTransition({ duration : 2,entryOffset : 100 ,direction :"right"} as any);
    useEffect(()=>{
        let startTouch :  number | undefined = undefined;
        function touchMove(event : TouchEvent){
            if(!startTouch){
                return
            }
            const currentPosition = event.changedTouches[0].clientX;
            const swipe = currentPosition - startTouch;
            const swipeLeft =  swipe < - 100;
            const swipeRight = swipe >  100;

            if(swipeLeft){
              navigateLeft({
                to  : pages[activeIndex].nextPage?pages[activeIndex].nextPage?.relativePath : pages[activeIndex].relativePath
              })
              return
            }
              
            if(swipeRight){
              navigateRight({
                to  : pages[activeIndex].previousPage?pages[activeIndex].previousPage?.relativePath : pages[activeIndex].relativePath
              })
            }

        }
        function touchStart(event : TouchEvent){
            startTouch = event.changedTouches[0].clientX;
            window.addEventListener('touchmove',touchMove);
        }
        window.addEventListener('touchstart',touchStart);
        window.addEventListener('touchend',touchEnd);

        function touchEnd(){
            window.removeEventListener('touchmove',touchMove);
            window.removeEventListener('touchend',touchEnd);
            startTouch = undefined;
        }

        return ()=>{
            window.removeEventListener('touchstart',touchStart);
            touchEnd();
        }

    })

  return (
    <ContainerUl>
        {pages.map((page,index)=>(
          <LiStyled><CircleIcon activeIndex={activeIndex} index={index} key={page.relativePath}   to={page.relativePath} active={page.active}/></LiStyled>

        ))}
    </ContainerUl>
  );
}
const MobileCircleNavBar  = animated(MobileCircleNavBarComponent)
export default MobileCircleNavBar;