// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/service-page.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */),
  "component---src-templates-success-page-tsx": () => import("./../../../src/templates/success-page.tsx" /* webpackChunkName: "component---src-templates-success-page-tsx" */),
  "component---src-templates-team-page-tsx": () => import("./../../../src/templates/team-page.tsx" /* webpackChunkName: "component---src-templates-team-page-tsx" */)
}

