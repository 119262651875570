import styled,{DefaultTheme, Interpolation, InterpolationFunction, ThemedStyledProps} from 'styled-components';
import media from '../../styles/utils/media'
import {Theme} from '../../styles/theme';


export const Nav = styled.nav`
	display:block;
	ul {
		display: none;
	}
	${media.sm`
	
		width: 100%;
		height: 100vh;
		background-color: (${(props) => props.theme.colors.background});
		ul {
			display: flex;
		}
	
		& > ul li:first-child,& > ul li:last-child {
			margin-left : 0;
		}
		& > ul li:nth-child(3) {
			margin-left : 2.29vw;
		}
		& > ul li:nth-child(2),& > ul li:nth-child(4) {
			margin-left : 1vw;
		}
	
	`}
`;

export const Ul = styled.ul`
    height: 100%;
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;

	margin:0;
	margin-left: 3.19vw;
	padding:0;

`;