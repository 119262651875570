import { css ,  FlattenSimpleInterpolation, Interpolation, StyledComponentPropsWithRef, ThemedStyledProps} from "styled-components"
import {Theme} from '../theme'


type Label =  keyof typeof Theme["breakpoints"]
type Media = typeof css;

interface ResponseProps{
    xlg: Media;
    lg: Media;
    md: Media;
    sm: Media;
}

export default Object.keys(Theme.breakpoints).reduce<ResponseProps>(( acc, label)=> {
  acc[label as Label ] = (first : any ,... args : any ) => css`
    @media (min-width: ${Theme.breakpoints[label as Label]}px) {
      ${css(first , ... args)};
    }
  `
  return acc
}, {} as any )